@import 'bootstrap/scss/variables';

#joiningConfirmationModal {
    .modal-dialog {
        align-items: center;
        display: flex;
        margin: auto;
        min-height: calc(100% - 1rem);

        /*
        max-width: rem-calc(789);

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(213);
        }
        */
    }

    .modal-content {
        padding: rem-calc(5 16 16 5);
    }

    .modal-header {
        align-items: center;
        flex-direction: column;
        margin: 0;
        text-align: center;
    }

    .joiningOptCheckLabel {
        margin-left: rem-calc(33);
    }

    .cross-btn {
        align-self: end;
        color: $accent;
        display: block;
        font-size: rem-calc(60);
        width: fit-content;
    }

    .modal-footer {
        margin: 0;
    }

    #StateCode {
        margin-right: rem-calc(76);
    }

    .modal-actions {
        .btn {
            font-size: rem-calc(14);
            font-weight: 500;
            padding-bottom: rem-calc(13);
            padding-top: rem-calc(13);

            @include media-breakpoint-up(sm) {
                max-width: rem-calc(258);
            }
        }
    }

    .eyebrow {
        @include eyebrow();

        margin-bottom: rem-calc(25);
    }

    .description {
        @include heading($sm-font-size: 30, $sm-line-height: 1.5, $multiple-size: false);

        margin-bottom: rem-calc(30);

        @include media-breakpoint-up(sm) {
            line-height: 2.67;
            margin-bottom: rem-calc(11);
        }
    }

    .custom-row {
        width: 75%;
    }

    .joining-link {
        color: $accent;
        font-family: $font-sans-serif;
        font-weight: bold;
        position: relative;
        text-decoration: underline;
    }

    .form-check input::after {
        left: rem-calc(2);
        top: rem-calc(-2);
    }

    .joining-opt-error {
        font-size: 13px !important;
        text-align: left;
    }
}
